import { Icon } from 'components/Icons/Icon'
import {
  RadioInput,
  RadioLabel,
  StyledErrorLabel,
  StyledIconAndLabelWrapper,
  StyledIconWrapper,
  StyledLabelWrapper,
  StyledRadioWrapper,
} from './InputRadio.style'
import { TooltipIconButton } from 'components/UI/Tooltip'
import { useEffect } from 'react'

export const InputRadio = ({
  label,
  children,
  value = '',
  id = value,
  name,
  onChange,
  disabled,
  error = false,
  errorMessage,
  checked,
  style,
  reversed = false,
  icon,
  iconWidth,
  iconColor,
  triggerValidation,
  validate,
  tooltip,
  ...rest
}: RadioButtonProps) => {
  useEffect(() => {
    if (triggerValidation) validate && validate()
  }, [triggerValidation])

  return (
    <StyledLabelWrapper htmlFor={id} $disabled={disabled}>
      <StyledRadioWrapper $reversed={reversed} style={style}>
        {reversed && (
          <StyledIconAndLabelWrapper>
            {icon && (
              <StyledIconWrapper $disabled={disabled} $color={iconColor}>
                <Icon
                  Icon={icon}
                  width={`${iconWidth ?? 18}px`}
                  stroke="inherit"
                />
              </StyledIconWrapper>
            )}
            <RadioLabel $reversed={reversed} htmlFor={id} $disabled={disabled}>
              {children ? (
                children
              ) : (
                <span dangerouslySetInnerHTML={{ __html: label ?? '' }} />
              )}
            </RadioLabel>
          </StyledIconAndLabelWrapper>
        )}
        <RadioInput
          type="radio"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          $error={error}
          checked={checked}
          {...rest}
        />
        {!reversed && (
          <RadioLabel htmlFor={id} $disabled={disabled}>
            {children ? (
              children
            ) : (
              <span dangerouslySetInnerHTML={{ __html: label ?? '' }} />
            )}
          </RadioLabel>
        )}
        {tooltip && (
          <TooltipIconButton
          // tooltipProps={{
          //   onShow: (instance) => {
          //     if (onTooltipShow) {
          //       onTooltipShow()
          //     }
          //   },
          // }}
          >
            {tooltip}
          </TooltipIconButton>
        )}
      </StyledRadioWrapper>
      {error && errorMessage && (
        <StyledErrorLabel $reverse={reversed} $icon={!!icon}>
          {errorMessage}
        </StyledErrorLabel>
      )}
    </StyledLabelWrapper>
  )
}

export interface RadioButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string
  children?: React.ReactNode
  value?: string
  id?: string
  name?: string
  checked?: boolean
  error?: boolean
  errorMessage?: string
  style?: React.CSSProperties
  reversed?: boolean
  icon?: React.FunctionComponent
  iconWidth?: number
  iconColor?: string
  tooltip?: string | React.ReactElement
  triggerValidation?: boolean
  validate?: () => void
}

export interface RadioInput {
  name?: string
  checked?: boolean | null
  value?: string
  label?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  reversed?: boolean
  error?: boolean
  icon?: React.FunctionComponent
  iconColor?: string
  tooltip?: string | React.ReactElement
  triggerValidation?: boolean
  validate?: () => void
}
