import Section from 'components/Section/Section'
import { StyledPrivacyRadioWrapper } from 'components/FormRichiestaContattoEnergySolutions/FormRichiestaContattoEnergySolutions.style'
import { InputRadio, RadioInput } from 'commons/FormComponents/InputRadio'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import Layout from 'Layout'
import { Colors } from 'commons/Theme'
import { ArrowUpRightIcon } from 'components/Icons/ArrowUpRightIcon'
import { Text } from 'components/FormContattaciSmartCity/FormContattaciSmartCity.style'

const RadioInputShowcase = () => {
  function handleOnChange() {
    // your code here
  }

  const radioInputList: Array<RadioInput & { testLabel: string }> = [
    {
      label: '',
      disabled: false,
      checked: null,
      testLabel: 'Default',
      name: 'a',
      value: 'a',
      error: false,
    },
    {
      label: '',
      disabled: false,
      checked: true,
      testLabel: 'Selected',
      name: 'b',
      value: 'b',
      error: false,
      icon: ArrowUpRightIcon,
    },
    {
      label: '',
      disabled: true,
      checked: null,
      testLabel: 'Incative/Disabled',
      name: 'c',
      value: 'c',
      error: false,
    },
    {
      label: 'Lorem ipsum dolor sit amet',
      disabled: false,
      checked: null,
      testLabel: 'Label right',
      name: 'right',
      value: 'd',
      error: false,
    },
    {
      label: 'Lorem ipsum <a href="https://www.google.com">dolor sit</a> amet',
      disabled: false,
      checked: null,
      testLabel: 'Label right with link',
      name: 'right',
      value: 'e',
      error: false,
    },
    {
      label: 'Lorem ipsum dolor sit amet',
      disabled: false,
      checked: null,
      testLabel: 'Label left',
      name: 'left',
      value: 'f',
      error: false,
      reversed: true,
    },
    {
      label: 'Lorem ipsum <a href="https://www.google.com">dolor sit</a> amet',
      disabled: false,
      checked: null,
      testLabel: 'Label left with link',
      name: 'left',
      value: 'g',
      error: false,
      reversed: true,
    },
    {
      label: 'Lorem ipsum <a href="https://www.google.com">dolor sit</a> amet',
      disabled: false,
      checked: null,
      testLabel: 'Label left with link and icon',
      name: 'icon',
      value: 'h',
      error: false,
      reversed: true,
      icon: ArrowUpRightIcon,
    },
    {
      label: 'Lorem ipsum <a href="https://www.google.com">dolor sit</a> amet',
      disabled: true,
      checked: null,
      testLabel: 'Label left with link and icon',
      name: 'icon',
      value: 'i',
      error: false,
      reversed: true,
      icon: ArrowUpRightIcon,
    },
    {
      label: 'Lorem ipsum <a href="https://www.google.com">dolor sit</a> amet',
      disabled: true,
      checked: null,
      testLabel:
        'Label left with link and an icon not properly written / Disabled',
      name: 'icon',
      value: 'j',
      error: false,
      reversed: true,
      icon: ArrowUpRightIcon,
    },
  ]

  return (
    <A2ADataContextProvider>
      <Layout pageTitle="Hub Commerciale Wave 4 - Radio Input">
        <>
          <Text $size={12} style={{ fontWeight: 400 }} $color={Colors.grey700}>
            *Campi obbligatori
          </Text>
          <Section backgroundColor={Colors.white} hasContainer={true}>
            {radioInputList?.map(
              (
                radioInput: RadioInput & { testLabel: string },
                index: number
              ) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      marginBottom: '5rem',
                      justifyContent: 'space-end',
                    }}
                  >
                    <div style={{ width: '50%' }}>{radioInput?.testLabel}</div>
                    <div style={{ textAlign: 'left' }}>
                      <InputRadio
                        name={radioInput?.name}
                        value={radioInput?.value}
                        disabled={radioInput?.disabled}
                        checked={radioInput.checked ?? undefined}
                        onChange={handleOnChange}
                        error={radioInput?.error || false}
                        label={radioInput?.label}
                        reversed={radioInput?.reversed}
                        icon={radioInput?.icon}
                        iconColor={radioInput?.iconColor}
                        tooltip={
                          <>
                            Attenzione, per l'invio dell'assegno è possibile
                            indicare esclusivamente un indirizzo italiano
                          </>
                        }
                      />
                    </div>
                  </div>
                )
              }
            )}
          </Section>
          <Section backgroundColor={Colors.blue100} hasContainer={true}>
            <StyledPrivacyRadioWrapper>
              <InputRadio
                name="privacy"
                value="privacy-yes"
                disabled={true}
                onChange={handleOnChange}
                error={false}
              >
                Radio button di prova
              </InputRadio>
              <InputRadio
                name="privacy"
                value="privacy-no"
                disabled={false}
                onChange={handleOnChange}
                error={false}
              >
                Radio button di prova che però nega tutto
              </InputRadio>
            </StyledPrivacyRadioWrapper>
          </Section>
        </>
      </Layout>
    </A2ADataContextProvider>
  )
}

export default RadioInputShowcase
