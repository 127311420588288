import { InputErrorDefaultStyle } from 'commons/FormComponents/General.style'
import { Colors, Palette, Rem } from 'commons/Theme'
import { StyledIcon } from 'components/Icons/Icon'
import styled, { css } from 'styled-components'

export const StyledRadioWrapper = styled.div<{
  $reversed?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $reversed }) => ($reversed ? 'space-between' : 'auto')};
  gap: ${({ $reversed }) => ($reversed ? 'auto' : Rem(16))};
  width: ${({ $reversed }) => ($reversed ? Rem(406) : 'auto')};
`

export const RadioLabel = styled.label<{
  $disabled?: boolean
  $reversed?: boolean
}>`
  margin: 0;
  font-size: ${Rem(16)};
  color: ${({ $disabled }) =>
    $disabled ? Palette.Greyscale['500'] : Palette.Greyscale['black']};
  font-weight: 400;
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  padding-right: ${({ $reversed }) => ($reversed ? Rem(60) : '0')};

  a {
    text-decoration: none;
    color: ${({ $disabled }) =>
      $disabled ? Palette.Greyscale['500'] : Palette.Azure['600']};
    &:hover {
      color: ${Colors.blue400};
    }
  }
`

export const RadioInput = styled.input<{
  $error?: boolean
}>`
  @keyframes select-radio {
    from {
      box-shadow: 0 0 0 0 ${Palette.Blue['800']};
    }

    to {
      box-shadow: 0 0 0 0.5px ${Palette.Blue['800']};
    }
  }

  &[type='radio'] {
    margin: 0;
    appearance: none;
    cursor: pointer;
    background-color: #fff;
    margin: 0;
    width: ${Rem(24)};
    height: ${Rem(24)};
    min-width: ${Rem(24)};
    min-height: ${Rem(24)};
    border: 1px solid ${Palette.Greyscale['800']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus,
    &:focus-visible {
      outline: none;
    }
    &:checked {
      border-color: ${Palette.Blue['600']};
    }
    &::before {
      content: '';
      display: block;
      background-color: ${Palette.Blue['800']};
      width: ${Rem(12)};
      height: ${Rem(12)};
      border-radius: 50%;
      opacity: 0;
    }
    &:checked::before {
      opacity: 1;
      //transition: all .3s ease;
      animation-duration: 100ms;
      animation-name: select-radio;
      animation-iteration-count: 2;
      animation-direction: alternate;
    }
    &:hover:not(:disabled) {
      border: 1px solid ${Palette.Blue['600']};
      background: ${Palette.Greyscale['white']};
      &:checked {
        border-color: ${Palette.Blue['600']};
      }
    }
    &:disabled {
      cursor: auto;
      border: 1px solid ${Palette.Greyscale['700']};
      background: ${Palette.Greyscale['100']};
      &::before {
        background-color: ${Colors.grey100};
      }
    }
    ${({ $error }) =>
      $error &&
      css`
        border-color: ${Palette.Red['border_error']};
      `};
  }
`

export const StyledIconWrapper = styled.div<{
  $disabled?: boolean
  $color?: string
}>`
  width: ${Rem(40)};
  height: ${Rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $disabled }) =>
    $disabled ? Palette.Greyscale['700'] : Palette.Azure['500']};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
  border-radius: 100%;

  ${StyledIcon} {
    stroke: ${Palette.Greyscale['white']};
  }
`

export const StyledIconAndLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${Rem(16)};
`

export const StyledLabelWrapper = styled.label<{
  $disabled?: boolean
}>`
  display: block;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`

export const StyledErrorLabel = styled.span<{
  $icon?: boolean
  $reverse?: boolean
}>`
  ${InputErrorDefaultStyle};
  margin-left: ${Rem(40)};
  cursor: default;

  ${({ $icon, $reverse }) =>
    $icon && $reverse
      ? css`
          margin-left: ${Rem(56)};
        `
      : $reverse &&
        css`
          margin-left: 0;
        `};
`
