import styled from 'styled-components'
import {
  BPSizes,
  Colors,
  ColumnsGap,
  Rem,
  StyledSupportingCaption,
  StyledSupportingHelper,
} from '../../commons/Theme'
import { InputErrorDefaultStyle } from 'commons/FormComponents/General.style'

export const StyledSingleInputWrapper = styled.div`
  padding-bottom: 20px;
`

export const StyledErrorAlert = styled.p`
  ${InputErrorDefaultStyle};
  display: block;
  margin: 0;
`

export const StyledSingleAdvice = styled.p`
  font-size: ${Rem(14)};
  color: ${Colors.grey600};
  font-weight: 400;
  display: block;
  margin: 0;
`

export const StyledFormRichiestaContattoEnergySolutionsForm = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  column-gap: ${ColumnsGap.sm};
  row-gap: 20px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    row-gap: 32px;
  }

  @media screen and (min-width: ${BPSizes.md}px) {
    column-gap: ${ColumnsGap.md};
  }
`

export const StyledPrivacy = styled.div<{ $disabled?: boolean }>`
  ${StyledSupportingCaption};
  color: ${Colors.grey800};
  grid-column: 1 / -1;

  p {
    margin-bottom: 24px;
    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  }

  a {
    color: ${Colors.azure600};
  }

  .helper {
    ${StyledSupportingHelper};
  }
`

export const StyledPrivacyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
  grid-column: 1 / -1;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const StyledPrivacyRadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    flex-direction: row;
  }
`

export const StyledErrorMessage = styled.div`
  ${InputErrorDefaultStyle};
  grid-column: 1 / -1;
  padding: 0;
  text-align: center;
`

export const Text = styled.div<{
  $size?: number
  $color?: string
  $align?: string
}>`
  font-size: ${({ $size }) => ($size ? Rem($size) : Rem(18))};
  color: ${({ $color }) => ($color ? $color : Colors.black)};
  text-align: ${({ $align }) => ($align ? $align : 'left')};
  line-height: 1.3;

  font-style: normal;
  font-weight: 500;

  a {
    color: ${Colors.azure600};
    &:hover {
      color: ${Colors.blue400};
    }
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    line-height: 1.35;
  }
`
